import React from "react";
import '../App.css';

function About() {
    return (
        <div className="App">
            <header className="App-header">
                <p style={{fontSize: 100}}>
                    About
                </p>
            </header>
        </div>
    );
}

export default About;